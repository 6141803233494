import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import Img from 'gatsby-image';

const Container = styled('span')``;

const Avatar = styled(Img)`
  border-radius: 100%;
`;

const Info = styled('span')`
  font-weight: bold;
`;

const Author = ({ id, name, avatar }) => (
  <Container>
    <Info>{name}</Info>
  </Container>
);

Author.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Author;
