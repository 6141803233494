import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import mq from '../styles/mq';
import { options, rhythm, scale } from '../utils/typography';
import CategoryLink from './CategoryLink';
import InlineAuthor from './InlineAuthor';
import TimeToRead from './TimeToRead';

const PostContainer = styled('article')`
  position: relative;
  padding: ${rhythm(3 / 2)};
  background: #fff;
  border-radius: 6px;
  margin-bottom: ${rhythm(1)};

  ${mq.medium} {
    transition: all 400ms;

    :hover {
      box-shadow: 0 -1px 40px 4px rgba(0, 0, 0, 0.1);
      transform: translate(0px, -4px);
    }
  }
`;

const PostHeader = styled('header')``;
const PostTitle = styled('h2')`
  ${css(scale(1))};
`;

const PostSummary = styled('p')`
  margin-top: ${rhythm(0.5)};
`;

const PostFooter = styled('footer')``;

const PostMeta = styled('div')`
  margin-top: ${rhythm(0.25)};
  margin-bottom: ${rhythm(1)};
  color: ${props => props.theme.secondaryColor};
  font-size: 15px;
  letter-spacing: 0.5px;
`;

const PostLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ListPost = ({
  link,
  title,
  date,
  descr,
  timeToRead,
  category,
  author,
}) => (
  <PostContainer>
    <PostLink to={link}>
      <PostTitle>{title}</PostTitle>
      <PostSummary dangerouslySetInnerHTML={{ __html: descr }} />
    </PostLink>
    <PostMeta>
      <InlineAuthor {...author} /> on {date} &middot;{' '}
      <TimeToRead timeToRead={timeToRead} />
    </PostMeta>
    <PostFooter>
      <CategoryLink category={category} />
    </PostFooter>
  </PostContainer>
);

ListPost.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  descr: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  author: PropTypes.object.isRequired,
};

export default ListPost;
